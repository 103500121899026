import React, { Component } from 'react';

export const LanguageContext = React.createContext({
  language: 'en',
  changeLanguage: () => null
});

export const LanguageConsumer = LanguageContext.Consumer;

export const defaultLanguage = localStorage.getItem('lang') !== null ? localStorage.getItem('lang') : navigator.language.substring(0, 2);

export class LanguageProvider extends Component {
  constructor(props) {
    super(props);
    this.state = { language: defaultLanguage }
    this.changeLanguage = this.changeLanguage.bind(this);
  }
  changeLanguage(activeLang) {
    this.setState({ 
      language: activeLang 
    });
    localStorage.setItem('lang', activeLang);
  }
  render() {
    return (
      <LanguageContext.Provider
        value={{
          language: this.state.language,
          changeLanguage: this.changeLanguage
        }}>
        {this.props.children}
      </LanguageContext.Provider>
    )
  }
}

