import React, { useState , useEffect } from 'react';
import fetch from 'node-fetch';
import { Value } from 'slate';
import HTMLRenderer from '../libraries/connect-editor/html-renderer';
import { LanguageConsumer } from '../libraries/LanguageContext';
import translate from './lang.json'
import loader from '../libraries/assets/ajax-loader.gif';
import './index.scss';

const initialPPContent = Value.fromJSON({
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [
          {
            object: 'text',
            leaves: [
              {
                text: '',
              },
            ],
          },
        ],
      },
    ],
  },
});

export const PrivacyPolicy = ({ language }) => {
  const [isLoading, setIsloading] = useState(true);
  const [PPContent, setPPContent] = useState(initialPPContent);
  useEffect(() => {
    fetch('https://api.c33.io/static-contents/get', {
      method: 'post',
      body: JSON.stringify({
        code: 'APP_PRIVACY' + translate[language].PPContentLocale,
        locale: language,  
      })
    })
    .then(response => response.json())
    .then(json => {
      setPPContent(Value.fromJSON(JSON.parse(json.data.content)))
      setIsloading(false);
    });
  });
  if (isLoading) {
    return (
      <LanguageConsumer>
        {
          ({ language }) => (
            <main className="privacy-policy">
              <div className="privacy-policy__inner">
                <h2 className="privacy-policy__title">
                  {translate[language].PPTitle}
                </h2>
                <div className="privacy-policy__content">
                  <img className="privacy-policy__loader" src={loader} alt="Loading content ..."/>
                </div>
              </div>
            </main>
          )
        }
      </LanguageConsumer>
    );
  } else {
    return (
      <LanguageConsumer>
        {
          ({ language }) => (
            <div className="privacy-policy">
              <div className="privacy-policy__inner">
                <h2 className="privacy-policy__title">
                  {translate[language].PPTitle}
                </h2>
                <div className="privacy-policy__content">
                  <HTMLRenderer value={PPContent} />
                </div>
              </div>
            </div>
          )
        }
      </LanguageConsumer>
    );
  }
}

export const PrivacyPolicyContainer = () => (
    <LanguageConsumer>
      {
        ({ language }) => (
          <PrivacyPolicy language={language} />
        )
      }
    </LanguageConsumer>
);

export default PrivacyPolicyContainer;

