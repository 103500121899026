import React, { useState , useEffect } from 'react';
import fetch from 'node-fetch';
import { Value } from 'slate';
import HTMLRenderer from '../libraries/connect-editor/html-renderer';
import { LanguageConsumer } from '../libraries/LanguageContext';
import translate from './lang.json';
import loader from '../libraries/assets/ajax-loader.gif';
import './index.scss';

const initialTOUContent = Value.fromJSON({
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [
          {
            object: 'text',
            leaves: [
              {
                text: '',
              },
            ],
          },
        ],
      },
    ],
  },
});

export const TermsOfUse = ({ language }) => {
  const [isLoading, setIsloading] = useState(true);
  const [TOUContent, setTOUContent] = useState(initialTOUContent);
  useEffect(() => {
    fetch('https://api.c33.io/static-contents/get', {
      method: 'post',
      body: JSON.stringify({
        code: 'APP_TERMS' + translate[language].TOUContentLocale,
        locale: language,
      })
    })
    .then(response => response.json())
    .then(json => {
      setTOUContent(Value.fromJSON(JSON.parse(json.data.content)))
      setIsloading(false);
    });
  });
  if (isLoading) {
    return (
      <LanguageConsumer>
        {
          ({ language }) => (
            <div className="terms-of-use">
              <div className="terms-of-use__inner">
                <h2 className="terms-of-use__title">
                  {translate[language].TOUTitle}
                </h2>
                <div className="terms-of-use__content">
                  <img className="terms-of-use__loader" src={loader} alt="Loading content ..."/>
                </div>
              </div>
            </div>
          )
        }
      </LanguageConsumer>
    );
  } else {
    return (
      <LanguageConsumer>
        {
          ({ language }) => (
            <main className="terms-of-use">
              <div className="terms-of-use__inner">
                <h2 className="terms-of-use__title">
                  {translate[language].TOUTitle}
                </h2>
                <div className="terms-of-use__content">
                  <HTMLRenderer value={TOUContent} />
                </div>
              </div>
            </main>
          )
        }
      </LanguageConsumer>
    );
  }  
}

export const TermsOfUseContainer = () => (
    <LanguageConsumer>
      {
        ({ language }) => (
          <TermsOfUse language={language} />
        )
      }
    </LanguageConsumer>
);

export default TermsOfUseContainer;

