/* eslint-disable default-case */
import React, { Component } from 'react';
import uuidv4 from 'uuid/v4';

const styles = {
  "heading-1": {
    fontSize: 16,
    display: 'block',
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 10,
  },
  "heading-2": {
    display: 'block',
    marginTop: 0,
    marginBottom: 22,
    fontSize: 22,
    fontFamily: 'sf_ui_displaylight',
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#414141',
  },
  "heading-3": {
    display: 'block',
    marginTop: 22,
    marginBottom: 22,
    fontSize: 22,
    fontFamily: 'sf_ui_displaylight',
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#414141',
  },
  "block-quote": {
    fontSize: 14,
    display: 'block',
    marginBottom: 10,
    borderLeftWidth: 5,
    borderLeftColor: '#eee',
    borderLeftStyle: 'solid',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    fontStyle: 'italic',
  },
  "paragraph": {
    display: 'block',
    paddingBottom: 18,
    fontSize: 18,
    fontFamily: 'sf_ui_displaylight',
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#4a4a4a',
  },
  "numbered-list": {
    display: 'block',
    marginBottom: 18,
    paddingLeft: 25,
  },
  "bulleted-list": {
    display: 'block',
    marginBottom: 18,
    paddingLeft: 25,
    fontSize: 18,
  },
  "list-item": {
    display: 'block',
    marginBottom: -9,
    paddingLeft: 25,
    fontSize: 18,
  },
  "list-bullet-odd": {
    float: 'left',
    marginLeft: -20,
    marginTop: 11,
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: '#4a4a4a',
  },
  "list-bullet-even": {
    float: 'left',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#444',
    width: 5,
    height: 5,
    borderRadius: 3,
    marginLeft: -15,
    marginTop: 8,
  },
  "list-number": {
    float: 'left',
    marginLeft: -25,
  },
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
  link: {
    color: '#337ab7',
  },
  underlined: {
    textDecoration: 'underline',
  }
}

const listCounter = {};

export class HTMLRenderer extends Component {
  parseText(textToParse, parents = []) {
    let nextParents = [];
    switch (textToParse.object) {
      case 'text':
        nextParents = [...parents, 'text'];
        return (
          <span data-type="text">
            {textToParse.leaves.map(node => this.parseNode(node, nextParents))}
          </span>
        );
      case 'leaf':
        if (textToParse.marks.length > 0){
          const nextMarks = [...textToParse.marks];
          nextMarks.shift();
          switch (textToParse.marks[0].type) {
            case "bold":
              return (
                <span data-type="bold" style={styles.bold}>
                  {this.parseText({
                    marks: nextMarks,
                    object: textToParse.object,
                    text: textToParse.text,
                  })}
                </span>
              );
            case "italic":
              return (
                <span data-type="italic" style={styles.italic}>
                  {this.parseText({
                    marks: nextMarks,
                    object: textToParse.object,
                    text: textToParse.text,
                  })}
                </span>
              );
            case "underlined":
              return (
                <span data-type="underlined" style={styles.underlined}>
                  {this.parseText({
                    marks: nextMarks,
                    object: textToParse.object,
                    text: textToParse.text,
                  })}
                </span>
              );
          }
        } else {
          return (
            <span data-type="leaf">{textToParse.text}</span>
          );
        }
        break;
      default:
        return null;
    }
  }
  parseBlock(blockToParse, parents = []) {
    let nextParents = [];
    let listBullet = null;

    if (blockToParse.type === 'numbered-list') {
      const listId = `numbered-list-${uuidv4()}`
      nextParents = [...parents, listId];
      listCounter[listId] = 1;
    } else {
      nextParents = [...parents, blockToParse.type];
    }
    if (blockToParse.type === 'list-item') {
      if (parents[parents.length - 1].indexOf('numbered-list') > -1) {
        listBullet =(
          <span data-type="list-item" style={styles['list-number']}>
            {listCounter[parents[parents.length - 1]]++}.
          </span>
        );
      } else {
        let listType = 'list-bullet-even';
        parents.map((item) => {
          if (item === 'bulleted-list') {
            listType = (listType === 'list-bullet-even') ? 'list-bullet-odd' : 'list-bullet-even';
          }
          return listType;
        });
        listBullet =(
          <span data-type={listType} style={styles[listType]} />
        );
      }
    }
    return (
      <span data-type={blockToParse.type} style={styles[blockToParse.type]}>
        {listBullet}
        {blockToParse.nodes.map(node => this.parseNode(node, nextParents))}
      </span>
    );
  }
  parseInline(inlineToParse, parents = []){
    let nextParents = [];
    switch (inlineToParse.type) {
      case "link":
        return (
          <span data-type="link" style={styles[inlineToParse.type]}>
            {inlineToParse.nodes.map(node => this.parseNode(node, nextParents))}
          </span>
        )
      default:
        return null;
    }
  }
  parseNode(nodeToParse, parents = []) {
    switch (nodeToParse.object) {
      case 'block':
        return this.parseBlock(nodeToParse, parents);
      case 'text':
      case 'leaf':
        return this.parseText(nodeToParse, parents);
      case 'inline':
        return this.parseInline(nodeToParse, parents);
      default:
        return null;
    }
  }
  render() {
    const content = this.props.value.toJSON();
    if (content.document.nodes.length < 1) return null;

    return (
      <div>
        {content.document.nodes.map(node => this.parseNode(node))}
      </div>
    )
  }
}

export default HTMLRenderer;
