import React from 'react';
import { Link } from "react-router-dom";
import { LanguageConsumer } from '../libraries/LanguageContext';
import translate from './lang.json';
import './index.scss';

const Pricing = () => {
  return (
    <LanguageConsumer>
      {
        ({ language }) => (
          <div className="pricing">
            <section className="pricing__detail">
              <div className="pricing__inner">
                <h2 className="pricing__detail__title">
                  {translate[language].pricingDetailTitle}
                </h2>
                <div className="pricing__features__wrapper">
                  <section className="pricing__features__box pricing__features__box--std">
                    <h3 className="pricing__features__title">
                      {translate[language].pricingFeatures1Title}
                    </h3>
                    <h4 className="pricing__features__sub-title">
                      {translate[language].pricingFeatures1SubTitle}
                    </h4>
                    <p className="pricing__features__p">
                      {translate[language].pricingFeatures1P}
                    </p>
                    <Link 
                      onClick={ () => window.scrollTo(0, 0) } 
                      to="/free-trial" 
                      className="pricing__features__list__action-btn"
                    >
                      {translate[language].pricingFeatures1Action}
                    </Link>
                    <ul className="pricing__features__list">
                      <li className="pricing__features__list-item">
                        <span className="ico"></span>
                        {translate[language].pricingFeatures1Item1}
                      </li>
                      <li className="pricing__features__list-item">
                        <span className="ico"></span>
                        {translate[language].pricingFeatures1Item2}
                      </li>
                      <li className="pricing__features__list-item">
                        <span className="ico"></span>
                        {translate[language].pricingFeatures1Item3}
                      </li>
                      <li className="pricing__features__list-item">
                        <span className="ico"></span>
                        {translate[language].pricingFeatures1Item4}
                      </li>
                      <li className="pricing__features__list-item">
                        <span className="ico"></span>
                        {translate[language].pricingFeatures1Item5}
                      </li>
                      <li className="pricing__features__list-item">
                        <span className="ico"></span>
                        {translate[language].pricingFeatures1Item6}
                      </li>
                      <li className="pricing__features__list-item">
                        <span className="ico"></span>
                        {translate[language].pricingFeatures1Item7}
                      </li>
                    </ul>
                    <span className="pricing__features__list__type">
                      {translate[language].pricingFeatures1Type1}
                    </span>
                  </section>
                  <section className="pricing__features__box pricing__features__box--custom">
                    <h3 className="pricing__features__title">
                      {translate[language].pricingFeatures2Title}
                    </h3>
                    <h4 className="pricing__features__sub-title">
                      {translate[language].pricingFeatures2SubTitle}
                    </h4>
                    <p className="pricing__features__p">
                      {translate[language].pricingFeatures2P}
                    </p>
                    <Link 
                      onClick={ () => window.scrollTo(0, 0) } 
                      to="/free-trial" 
                      className="pricing__features__list__action-btn">
                      {translate[language].pricingFeatures2Action}
                    </Link>
                    <h4 className="pricing__features__info">
                      {translate[language].pricingFeatures2Info}
                    </h4>
                    <ul className="pricing__features__list">
                      <li className="pricing__features__list-item">
                        <span className="ico"></span>
                        {translate[language].pricingFeatures2Item1}
                      </li>
                      <li className="pricing__features__list-item">
                        <span className="ico"></span>
                        {translate[language].pricingFeatures2Item2}
                      </li>
                      <li className="pricing__features__list-item">
                        <span className="ico"></span>
                        {translate[language].pricingFeatures2Item3}
                      </li>
                      <li className="pricing__features__list-item">
                        <span className="ico"></span>
                        {translate[language].pricingFeatures2Item4}
                      </li>
                      <li className="pricing__features__list-item">
                        <span className="ico"></span>
                        {translate[language].pricingFeatures2Item5}
                      </li>
                    </ul>
                    <span className="pricing__features__list__separator"></span>
                  </section>
                  <span className="clear"></span>
                </div>
              </div>
            </section>
            <section className="pricing__faq">
              <div className="pricing__inner">
                <h2 className="pricing__faq__title">
                  {translate[language].pricingFaqTitle}
                </h2>
                <ul className="pricing__faq__list">
                  <li className="pricing__faq__list-item">
                    <h3 className="pricing__faq__list-item__question">
                      {translate[language].pricingFaqQuestion1}
                    </h3>
                    <p className="pricing__faq__list-item__answer">
                      {translate[language].pricingFaqAnswer1}
                    </p>
                  </li>
                  <li className="pricing__faq__list-item">
                    <h3 className="pricing__faq__list-item__question">
                      {translate[language].pricingFaqQuestion2}
                    </h3>
                    <p className="pricing__faq__list-item__answer">
                      {translate[language].pricingFaqAnswer2}
                    </p>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        )
      }
    </LanguageConsumer>
  );
}

export default Pricing;

