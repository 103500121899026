import React from 'react';
import { Link } from "react-router-dom";
import { LanguageConsumer } from '../libraries/LanguageContext';
import translate from './lang.json';
import './index.scss';

const Home = () => {
  return (
    <LanguageConsumer>
      {
        ({ language }) => (
          <div className="home">
            <section className="home__hero">
              <div className="home__inner">
                <div className="wrapper">
                  <h2>Connect33<span className="separator"></span></h2>
                  <h3>{translate[language].homeHeroTitle}</h3>
                  <Link 
                    onClick={ () => window.scrollTo(0, 0) } 
                    to="/free-trial"
                  >
                    {translate[language].homeHeroAction}
                  </Link>
                </div>
              </div>
              <div className="bg"></div>
              <div className="overlay"></div>
            </section>
            <section className="home__include">
              <div className="home__inner">
                <header className="home__include__header">
                  <h2>
                    {translate[language].homeIncludeTitle}
                    <span className="separator"></span>
                  </h2>
                </header>
                <h3 className="home__include__sub-header">
                  {translate[language].homeIncludeSubTitle}
                </h3>
                <ul className="home__include__list">
                  <li className="home__include__list-item home__include__list-item__news">
                    <span className="ico"></span>
                    <h4 className="home__include__list-title">
                      {translate[language].homeIncludeNewsTitle}
                    </h4>
                    <p className="home__include__list-desc">
                      {translate[language].homeIncludeNewsDesc}
                    </p>
                  </li>
                  <li className="home__include__list-item home__include__list-item__events">
                    <span className="ico"></span>
                    <h4 className="home__include__list-title">
                      {translate[language].homeIncludeEventsTitle}
                    </h4>
                    <p className="home__include__list-desc">
                      {translate[language].homeIncludeEventsDesc}
                    </p>
                  </li>
                  <li className="home__include__list-item home__include__list-item__benefits">
                    <span className="ico"></span>
                    <h4 className="home__include__list-title">
                      {translate[language].homeIncludeBenefitsTitle}
                    </h4>
                    <p className="home__include__list-desc">
                      {translate[language].homeIncludeBenefitsDesc}
                    </p>
                  </li>
                  <li className="home__include__list-item home__include__list-item__challenges">
                    <span className="ico"></span>
                    <h4 className="home__include__list-title">
                      {translate[language].homeIncludeChallengesTitle}
                    </h4>
                    <p className="home__include__list-desc">
                      {translate[language].homeIncludeChallengesDesc}
                    </p>
                  </li>
                  <li className="home__include__list-item home__include__list-item__employee">
                    <span className="ico"></span>
                    <h4 className="home__include__list-title">
                      {translate[language].homeIncludeEmployeeTitle}
                    </h4>
                    <p className="home__include__list-desc">
                      {translate[language].homeIncludeEmployeeDesc}
                    </p>
                  </li>
                  <li className="home__include__list-item home__include__list-item__social">
                    <span className="ico"></span>
                    <h4 className="home__include__list-title">
                      {translate[language].homeIncludeSocialTitle}
                    </h4>
                    <p className="home__include__list-desc">
                      {translate[language].homeIncludeSocialDesc}
                    </p>
                  </li>
                </ul>
              </div>  
            </section>
            <section className="home__benefits">
              <div className="home__inner">
                <header className="home__benefits__header">
                  <h2>
                    {translate[language].homeBenefitsTitle}
                    <span className="separator"></span>
                  </h2>
                </header>
                <h3 className="home__benefits__sub-header">
                  {translate[language].homeBenefitsSubTitle}
                </h3>
                <ul className="home__benefits__list">
                  <li className="home__benefits__list-item">
                    <h4 className="home__benefits__list-title">
                      {translate[language].homeBenefitsTitle1}
                    </h4>
                    <p className="home__benefits__list-desc">
                      {translate[language].homeBenefitsDesc1}
                    </p>
                  </li>
                  <li className="home__benefits__list-item">
                    <h4 className="home__benefits__list-title">
                      {translate[language].homeBenefitsTitle2}
                    </h4>
                    <p className="home__benefits__list-desc">
                      {translate[language].homeBenefitsDesc2}
                    </p>
                  </li>
                  <li className="home__benefits__list-item">
                    <h4 className="home__benefits__list-title">
                      {translate[language].homeBenefitsTitle3}
                    </h4>
                    <p className="home__benefits__list-desc">
                      {translate[language].homeBenefitsDesc3}
                    </p>
                  </li>
                  <li className="home__benefits__list-item">
                    <h4 className="home__benefits__list-title">
                      {translate[language].homeBenefitsTitle4}
                    </h4>
                    <p className="home__benefits__list-desc">
                      {translate[language].homeBenefitsDesc4}
                    </p>
                  </li>
                  <li className="home__benefits__list-item">
                    <h4 className="home__benefits__list-title">
                      {translate[language].homeBenefitsTitle5}
                    </h4>
                    <p className="home__benefits__list-desc">
                      {translate[language].homeBenefitsDesc5}
                    </p>
                  </li>
                  <li className="home__benefits__list-item">
                    <h4 className="home__benefits__list-title">
                      {translate[language].homeBenefitsTitle6}
                    </h4>
                    <p className="home__benefits__list-desc">
                      {translate[language].homeBenefitsDesc6}
                    </p>
                  </li>
                </ul>
              </div>  
            </section>
            <section className="home__teaser">
              <div className="home__inner">
                <h2>{translate[language].homeTeaserTitle}</h2>
                <Link 
                  onClick={ () => window.scrollTo(0, 0) } 
                  to="/free-trial"
                >
                  {translate[language].homeTeaserAction}
                </Link>
              </div>  
            </section>
          </div>
        )
      }
    </LanguageConsumer>
  );
}

export default Home;

