import React, {useState} from 'react';
import { NavLink, Link } from "react-router-dom";
import { LanguageConsumer } from '../LanguageContext';
import translate from './lang.json'
import './index.scss';

const Header = () => {
  const [active, setActive] = useState(false);
  let headerClassName = 'header';
  if (active === true) {
    headerClassName = 'header header--show-nav';
  }
  const onClickToggle = () => {
    window.scrollTo(0, 0);
    setActive(!active);
  }
  const onClickNav = () => {
    window.scrollTo(0, 0);
    setActive(false);
  }
  return (
    <LanguageConsumer>
      {
        ({ language }) => (
          <header className={headerClassName}>
            <h1 className="header__logo">
              <Link to="/">Connect33<span></span></Link>
            </h1>
            <nav className="header__main-nav">
              <ul className="header__main-nav__list">
                <li className="header__main-nav__list-item">
                  <NavLink 
                    exact 
                    to="/"
                    onClick={onClickNav} 
                    className="header__main-nav__list-link"
                  >
                    {translate[language].headerMainNav1}
                  </NavLink>
                </li>
                <li className="header__main-nav__list-item">
                  <NavLink 
                    to="/pricing" 
                    onClick={onClickNav} 
                    className="header__main-nav__list-link"
                  >
                    {translate[language].headerMainNav2}
                  </NavLink>
                </li>
                <li className="header__main-nav__list-item">
                  <NavLink 
                    to="/free-trial" 
                    onClick={onClickNav} 
                    className="header__main-nav__list-link"
                  >
                    {translate[language].headerMainNav3}
                  </NavLink>
                </li>
                <li className="header__main-nav__list-item">
                  <NavLink 
                    to="/about" 
                    onClick={onClickNav} 
                    className="header__main-nav__list-link"
                  >
                    {translate[language].headerMainNav4}
                  </NavLink>
                </li>
              </ul>
            </nav>
            <button className="header__main-nav__toggle" onClick={onClickToggle}>
              <div className="burger"><span></span><span></span><span></span></div>
              <div className="cross"><span></span><span></span></div>
            </button>
            <div className="header__main-nav__overlay"></div>
          </header>
        )
      }
    </LanguageConsumer>
  )
};

export default Header;

