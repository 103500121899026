import 'babel-polyfill';
import 'react-app-polyfill/ie9';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Router, Route } from "react-router-dom";
import { LanguageProvider } from './components/libraries/LanguageContext';
import Header from './components/libraries/header';
import FooterContainer from './components/libraries/footer';
import Home from './components/home';
import Pricing from './components/pricing';
import FreeTrialContainer from './components/free-trial';
import About from './components/about';
import PrivacyPolicyContainer from './components/privacy-policy';
import TermsOfUseContainer from './components/terms-of-use';
import './index.css';
const history = require('history').createBrowserHistory()

ReactGA.initialize('UA-138012444-1');


history.listen(location => {
	ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname)
})

export const App = () => {
  useEffect(() => {

    ReactGA.pageview(window.location.pathname)
  }, [])

  return (
  <LanguageProvider>
    <Router history={history}>
      <div className="container">
        <Header />

        <Route path="/" exact component={Home} />
        <Route path="/pricing" exact component={Pricing} />
        <Route path="/free-trial" exact component={FreeTrialContainer} />
        <Route path="/about" exact component={About} />
        <Route path="/privacy-policy" exact component={PrivacyPolicyContainer} />
        <Route path="/terms-of-use" exact component={TermsOfUseContainer} />

        <FooterContainer />        
      </div>
    </Router>
  </LanguageProvider> 
);
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
