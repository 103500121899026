import React from 'react';
import { LanguageConsumer } from '../libraries/LanguageContext';
import translate from './lang.json';
import './index.scss';

const About = () => {
  return (
    <LanguageConsumer>
      {
        ({ language }) => (
          <div className="about">
            <section className="about__hero">
              <div className="about__inner">
                <div className="wrapper">
                  <h2>Connect33<span className="separator"></span></h2>
                  <h3>{translate[language].heroTitle}</h3>
                </div>
              </div>
              <div className="bg"></div>
              <div className="overlay"></div>
            </section>
            <section className="about__detail">
              <div className="about__inner">
                <section className="about__detail__inner">
                  <header className="about__detail__header">
                    <h2>{translate[language].aboutDetailTitle1}<span className="separator"></span></h2>
                  </header>
                  <section className="about__detail__content">
                    <div dangerouslySetInnerHTML={{__html: translate[language].aboutDetailContent1.join('\n') }} />
                  </section>
                </section>
                <section className="about__detail__inner">
                  <header className="about__detail__header">
                    <h2>{translate[language].aboutDetailTitle2}<span className="separator"></span></h2>
                  </header>
                  <section className="about__detail__content">
                    <div dangerouslySetInnerHTML={{__html: translate[language].aboutDetailContent2.join('\n') }} />
                  </section>
                </section>
              </div>
            </section>
          </div>
        )
      }
    </LanguageConsumer>
  );
}

export default About;

