import React, { useState } from 'react';
import Select from 'react-select';
import { Link } from "react-router-dom";
import countryOptions from './countries.json';
import { LanguageConsumer } from '../libraries/LanguageContext';
import translate from './lang.json';
import ftHero from './assets/hero.png';
import './index.scss';

export const FreeTrial = ({ language }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }
  const companySizeOptions = [
    { value: '1 - 100', label: translate[language].freeTrialFormCompanySize1 },
    { value: '101 - 500', label: translate[language].freeTrialFormCompanySize2 },
    { value: '501 - 1000', label: translate[language].freeTrialFormCompanySize3 },
    { value: '1001 - 5000', label: translate[language].freeTrialFormCompanySize4 },
    { value: '5000 +', label: translate[language].freeTrialFormCompanySize5 },
  ];
  const optionsStyles = {
    control: (styles, state) => ({
      ...styles,
      height: 62,
      fontSize: 18,
      color: '#4a4a4a',
      borderColor: '#d6d6d6',
      borderRadius: 6,
      backgroundColor: '#ffffff',
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: 'none',
    }),
    valueContainer: styles => ({
      ...styles,
      paddingRight: 20,
      paddingLeft: 20,
    }),
    singleValue: styles => ({
      ...styles,
      marginRight: 0,
      marginLeft: 0,
      paddingRight: 0,
      paddingLeft: 0,
    }),
    menu: styles => ({
      ...styles,
      borderRadius: 6,
    }),
  }
  const [workEmail, setWorkEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [country, setCountry] = useState('');
  const [acceptForm, setAcceptForm] = useState(false);
  const [formMessage, setFormMessage] = useState('');
  const formStateDefault = 'free-trial__form';
  const formStateError = 'free-trial__form free-trial__form--error';
  const formStateSuccess = 'free-trial__form free-trial__form--success';
  const [formState, setFormState] = useState(formStateDefault);
  const formMessageRef = React.createRef();
  const showFormMessage = () => { window.scrollTo(0, formMessageRef.current.offsetTop - 110) };
  const formMessageErrorAll = translate[language].freeTrialErrorAll;
  const formMessageErrorAcceptForm = translate[language].freeTrialErrorAcceptForm;
  const formMessageErrorEmail = translate[language].freeTrialErrorEmail;
  const formMessageSuccess = translate[language].freeTrialSuccess;
  let formAcceptClassName = '';
  if (acceptForm === true) {
    formAcceptClassName = 'checked';
  }
  const onClickAcceptForm = () => {
    setAcceptForm(!acceptForm);
  }
  const validateForm = () => {
    if (workEmail !== '' && firstName !== '' && companySize !== '' && companyName !== '' && country !== '') {
      if (acceptForm === true) {
        return true;
      } else {
        setFormMessage(formMessageErrorAcceptForm)
        setFormState(formStateError)
        showFormMessage()
      }
    } else {
      setFormMessage(formMessageErrorAll)
      setFormState(formStateError)
      showFormMessage()
    }
  }
  const validateEmail = (workEmail) => {
    if (String(workEmail).toLowerCase().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      return true;
    } else {
      setFormMessage(formMessageErrorEmail)
      setFormState(formStateError)
      showFormMessage()
    }
  }
  const sendMail = (type) => {
    if (validateForm()) {
      if (validateEmail(workEmail)) {
        fetch('https://mandrillapp.com/api/1.0/messages/send.json', {
          method: 'post',
          body: JSON.stringify({
            key: 'CaRIjOg_FE2LC71nfQthHg',
            message: {
              subject: `[${type}] CONNECT33 Inquiry from Website`,
              text: `Email: ${workEmail} \n\nFirstname: ${firstName} \n\nLastname: ${lastName} \n\nCompany Name: ${companyName} \n\nCompany Size: ${companySize.label} \n\nCountry: ${country.label}`,
              from_email: 'noreply@mango33.com',
              from_name: `${firstName} ${lastName}`,
              to: [
                {
                  email: 'ahadinoto@mango33.com',
                  type: 'to'
                },
                {
                  email: 'hello@mango33.com',
                  type: 'to'
                }
              ],
            }
          })
        })
        .then(response => response.json())
        .then(json => {
          if (json[0].status === 'sent') {
            setWorkEmail('');
            setFirstName('');
            setLastName('');
            setCompanyName('');
            setCompanySize('');
            setCountry('');
            setAcceptForm(false);
            setFormState(formStateSuccess);
            setFormMessage(formMessageSuccess);
          }
        })
        .then(showFormMessage());
      }
    }
  }
  const startTrial = (e) => {
    sendMail('TRIAL REQUEST');
  }
  const startDemo = (e) => {
    sendMail('DEMO REQUEST');
  }
  return (
    <LanguageConsumer>
      {
        ({ language }) => (
          <div className="free-trial">
            <section className="free-trial__hero">
              <div className="free-trial__inner">
                <div className="wrapper">
                  <div className="wrapper-inner">
                    <h2 className="free-trial__hero__title">
                      {translate[language].freeTrialHeroTitle}
                    </h2>
                    <div dangerouslySetInnerHTML={{__html: translate[language].freeTrialDownloadAppStore.join('\n') }} />
                    <div dangerouslySetInnerHTML={{__html: translate[language].freeTrialDownloadPlayStore.join('\n') }} />
                  </div>
                  <img className="free-trial__hero__image" src={ftHero} alt=""/>
                </div>
              </div>
              <div className="bg"></div>
            </section>
            <section className={formState}>
              <div className="free-trial__inner">
                <header className="free-trial__form__header">
                  <h2>{translate[language].freeTrialFormTitle}</h2>
                  <p>{translate[language].freeTrialFormSubTitle}</p>
                </header>
                <div className="free-trial__form__message-wrapper" ref={formMessageRef}>
                  <div className="free-trial__form__message">
                      {formMessage}
                  </div>
                </div>
                <form action="#" className="free-trial__form__inputs" onSubmit={e => e.preventDefault()}>
                  <p className="free-trial__form__notice">
                    {translate[language].freeTrialFormNotice}
                  </p>
                  <ul className="free-trial__form__list">
                    <li className="free-trial__form__list-item">
                      <label className="free-trial__form__label">
                        {translate[language].freeTrialFormLabel1}
                      </label>
                      <input 
                        className="free-trial__form__txt" 
                        type="text" 
                        placeholder=""
                        value={workEmail}
                        onChange={ (e) => {setWorkEmail(e.target.value)} }
                      />
                    </li>
                    <li className="free-trial__form__list-item">
                      <label className="free-trial__form__label">
                        {translate[language].freeTrialFormLabel2}
                      </label>
                      <input 
                        className="free-trial__form__txt" 
                        type="text" 
                        placeholder=""
                        value={firstName}
                        onChange={ (e) => {setFirstName(e.target.value)} }
                      />
                    </li>
                    <li className="free-trial__form__list-item">
                      <label className="free-trial__form__label">
                        {translate[language].freeTrialFormLabel3}
                      </label>
                      <input 
                        className="free-trial__form__txt" 
                        type="text" 
                        placeholder=""
                        value={lastName}
                        onChange={ (e) => {setLastName(e.target.value)} }
                      />
                    </li>
                    <li className="free-trial__form__list-item">
                      <label className="free-trial__form__label">
                        {translate[language].freeTrialFormLabel4}
                      </label>
                      <input 
                        className="free-trial__form__txt" 
                        type="text" 
                        placeholder=""
                        value={companyName}
                        onChange={ (e) => {setCompanyName(e.target.value)} }
                      />
                    </li>
                    <li className="free-trial__form__list-item">
                      <label className="free-trial__form__label">
                        {translate[language].freeTrialFormLabel5}
                      </label>
                      <Select
                        options={companySizeOptions}
                        className={'free-trial__dropdown__options'}
                        classNamePrefix={'free-trial__dropdown'}
                        placeholder={translate[language].freeTrialFormLabel5Placeholder}
                        isSearchable={false}
                        styles={optionsStyles}
                        value={companySize}
                        onChange={ (e) => {setCompanySize(e)} }
                      />
                    </li>
                    <li className="free-trial__form__list-item">
                      <label className="free-trial__form__label">
                        {translate[language].freeTrialFormLabel6}
                      </label>
                      <Select
                        options={countryOptions[language]}
                        className={'free-trial__dropdown__options'}
                        classNamePrefix={'free-trial__dropdown'}
                        placeholder={translate[language].freeTrialFormLabel6Placeholder}
                        styles={optionsStyles}
                        value={country}
                        onChange={ (e) => {setCountry(e)} }
                      />
                    </li>
                  </ul>
                  <div className="free-trial__form__accept">
                    <label 
                      className={formAcceptClassName} 
                      onClick={onClickAcceptForm}
                    >
                      <span className="ico"></span>
                      {translate[language].freeTrialFormAccept1}&nbsp;
                      <Link 
                        onClick={scrollToTop} 
                        to="/terms-of-use"
                      >
                        {translate[language].freeTrialFormAccept2}
                      </Link> 
                      &nbsp;{translate[language].freeTrialFormAccept3}&nbsp;
                      <Link 
                        onClick={scrollToTop} 
                        to="/privacy-policy"
                      >
                        {translate[language].freeTrialFormAccept4}
                      </Link>{translate[language].freeTrialFormAccept5}.
                    </label>
                  </div>
                  <div className="free-trial__form__action__wrapper">
                    <button
                      className="
                        free-trial__form__action
                        free-trial__form__action--trial
                      "
                      onClick={startTrial}
                    >
                      {translate[language].freeTrialFormAction1}
                    </button>
                    <button
                      className="
                        free-trial__form__action
                        free-trial__form__action--demo
                      "
                      onClick={startDemo}
                    >
                      {translate[language].freeTrialFormAction2}
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        )
      }
    </LanguageConsumer>
  );
}

export const FreeTrialContainer = () => (
    <LanguageConsumer>
      {
        ({ language }) => (
          <FreeTrial language={language} />
        )
      }
    </LanguageConsumer>
);

export default FreeTrialContainer;

