import React from 'react';
import { NavLink } from "react-router-dom";
import Select from 'react-select';
import { LanguageConsumer } from '../LanguageContext';
import translate from './lang.json'
import './index.scss';

export const Footer = ({ language }) => {
  const langOptions = [
    { value: 'en', label: translate[language].footerLangOptionEN },
    { value: 'id', label: translate[language].footerLangOptionID },
    { value: 'de', label: translate[language].footerLangOptionDE },
  ];
  const langOptionsStyles = {
    control: (styles, state) => ({
      ...styles,
      paddingTop: 2,
      width: 80,
      minHeight: 26,
      height: 26,
      fontSize: 18,
      lineHeight: 1,
      border: 'none',
      backgroundColor: '#1a1a1a'
    }),
    indicatorsContainer: styles => ({
      ...styles,
      display: 'none'
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: 'none'
    }),
    valueContainer: styles => ({
      ...styles,
      padding: 0,
    }),
    singleValue: styles => ({
      ...styles,
      height: 26,
      paddingTop: 3,
      color: '#ffffff'
    }),
    input: styles => ({
      ...styles,
      outline: 'none'
    })
  }
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }
  let languageLabel = translate[language].footerLangOptionEN;
  if (language !== 'en') {
    if (language === 'id') {
      languageLabel = translate[language].footerLangOptionID;
    } else {
      languageLabel = translate[language].footerLangOptionDE;
    }
  }
  return (
    <LanguageConsumer>
      {
        ({ language, changeLanguage }) => (
        <div className="footer-container">
          <section className="mango33-contact">
            <h2 className="mango33-contact__logo">
              Mango33
              <span></span>
            </h2>
            <section className="mango33-contact__email">
              <a href="mailto:hello@mango33.com">
                hello@mango33.com
                <span className="ico"></span>
              </a>
            </section>
            <section className="mango33-contact__address">
              <ul className="mango33-contact__address__list">
                <li className="mango33-contact__address__list-item">
                  <address className="mango33-contact__address__list-branches">
                    <h3 className="mango33-contact__address__list-branches__country">
                      {translate[language].footerMango33Country1}
                    </h3>
                    <div className="mango33-contact__address__list-branches__location">
                      <div dangerouslySetInnerHTML={{__html: translate[language].footerMango33Location1.join('\n') }} />
                    </div>
                  </address>
                </li>
                <li className="mango33-contact__address__list-item">
                  <address className="mango33-contact__address__list-branches">
                    <h3 className="mango33-contact__address__list-branches__country">
                      {translate[language].footerMango33Country2}
                    </h3>
                    <div className="mango33-contact__address__list-branches__location">
                      <div dangerouslySetInnerHTML={{__html: translate[language].footerMango33Location2.join('\n') }} />
                    </div>
                  </address>
                </li>
                <li className="mango33-contact__address__list-item">
                  <address className="mango33-contact__address__list-branches">
                    <h3 className="mango33-contact__address__list-branches__country">
                      {translate[language].footerMango33Country3}
                    </h3>
                    <div className="mango33-contact__address__list-branches__location">
                      <div dangerouslySetInnerHTML={{__html: translate[language].footerMango33Location3.join('\n') }} />
                    </div>
                  </address>
                </li>
                <li className="mango33-contact__address__list-item">
                  <address className="mango33-contact__address__list-branches">
                    <h3 className="mango33-contact__address__list-branches__country">
                      {translate[language].footerMango33Country4}
                    </h3>
                    <div className="mango33-contact__address__list-branches__location">
                      <div dangerouslySetInnerHTML={{__html: translate[language].footerMango33Location4.join('\n') }} />
                    </div>
                  </address>
                </li>
              </ul>
            </section>
          </section>
          <footer className="footer">
            <section className="footer__nav">
              <ul className="footer__nav__list">
                <li className="footer__nav__list-item">
                  <NavLink 
                    onClick={scrollToTop} 
                    to="/privacy-policy" 
                    className="footer__nav__list-link"
                  >
                    {translate[language].footerNavItem1}
                  </NavLink>
                </li>
                <li className="footer__nav__list-item">
                  <NavLink 
                    onClick={scrollToTop} 
                    to="/terms-of-use" 
                    className="footer__nav__list-link"
                  >
                    {translate[language].footerNavItem2}
                  </NavLink>
                </li>
              </ul>
              <section className="footer__lang-selector">
                <span className="ico"></span>
                <Select
                  options={langOptions}
                  className={'footer__lang-selector__options'}
                  classNamePrefix={'footer__lang-selector'}
                  defaultValue={{ value: language, label: languageLabel }}
                  isSearchable={false}
                  styles={langOptionsStyles}
                  onChange={ (activeLang) => { changeLanguage(activeLang.value) }}
                />
              </section>
            </section>
            <div className="footer__copyright">
              <div dangerouslySetInnerHTML={{__html: translate[language].footerCopyright.join('\n') }} />
            </div>
          </footer>
        </div>
        )
      }
    </LanguageConsumer>
  );
}

export const FooterContainer = () => (
    <LanguageConsumer>
      {
        ({ language }) => (
          <Footer language={language} />
        )
      }
    </LanguageConsumer>
);

export default FooterContainer;

